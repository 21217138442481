import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from './../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class CrifService {
	// tslint:disable-next-line:max-line-length
	// private esBaseUrl = environment.urls.elastic.base;
	private esBaseUrl = environment.urls.elastic.lambda;

	private apiBaseUrl = environment.urls.api.base;

	constructor(private http: HttpClient) {
	}

	getCrifDataRequest(custCode: any, loanUid: any, partnerCode: any): Observable<any> {
		console.log(custCode, loanUid, partnerCode);
		/* const requestBody = {
			query : {
				bool : {
					must : [
						{
							match_phrase : {
								loan_uid : loanUid
							}
						},
						{
							match_phrase : {
								partner_code : partnerCode
							}
						},
						{
							match_phrase : {
								customer_code : custCode
							}
						}
					]
				}
			}
		};
		
		/* let headers = new HttpHeaders();
		if (environment.urls.elastic.auth !== "") {
			headers = headers.set('Authorization', environment.urls.elastic.auth);
		} */

		/* return this.http.post(
			this.esBaseUrl + environment.urls.elastic.indices.crif_data_requests + '/_search?' +
			'size=10000&' +
			'filter_path=hits.hits._source.*',
			requestBody
		); */ 
		let body = {
			index: environment.urls.elastic.indices.crif_data_requests,
			body: {
			  query: {
				bool: {
				  must: [],
				},
			  },
			  sort: [],
			  aggs: {
				total_count: {
				  value_count: {
					field: '_id',
				  },
				},
			  },
			  user: {},
			},
			_source: ['*'],
			size: 10000,
			from: 0,
			filterPath: [],
			sort: ['t:desc'],
		  };
		  return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
	}

	public getCrifDataRequests(): Observable<any> {
		// return this.http.get(this.esBaseUrl + environment.urls.elastic.indices.crif_data_requests + '/_search/?sort=t:desc&size=10000');
		let body = {
			index: environment.urls.elastic.indices.crif_data_requests,
			body: {
			  query: {
				bool: {
				  must: [],
				},
			  },
			  sort: [],
			  aggs: {
				total_count: {
				  value_count: {
					field: '_id',
				  },
				},
			  },
			  user: {},
			},
			_source: [],
			size: 10000,
			from: 0,
			filterPath: [],
			sort: ['t:desc'],
		  };
		  return this.http.post(this.esBaseUrl + 'search', body, this.esHeaders());
	}

	getS3Url(url: any): Observable<any> {
		console.log(url);
		let headers = new HttpHeaders();
	    let accessToken:any = localStorage.getItem('accessToken');
		if (accessToken) {
			headers = headers.set('Token', `Bearer ${accessToken}`);
		}
		const httpOptions = {
			headers : headers,
		   params: {s3_url: url}
		 };

		return this.http.get(
			this.apiBaseUrl + 'crif_s3_url', httpOptions
		);
	}

	getXMLFile(url): Observable<any> {
		let headers = new HttpHeaders();
	    let accessToken:any = localStorage.getItem('accessToken');
		if (accessToken) {
			headers = headers.set('Token', `Basic ${accessToken}`);
		}
		return this.http.get(url, {responseType: 'text',headers: headers});
	}
	esHeaders() {
		if (environment.urls.elastic.bypass_es_auth) {
			return {};
		}
		let headers = new HttpHeaders();
		/* if (environment.urls.elastic.auth !== "") {
			headers = headers.set('Authorization', environment.urls.elastic.auth);
		} */
		let accessToken:any = localStorage.getItem('accessToken');
		if (accessToken) {
			headers = headers.set('Token', `Bearer ${accessToken}`);
		}
		return {headers};
	}
}

